import Vue from 'vue'

import "lazysizes/plugins/object-fit/ls.object-fit";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/respimg/ls.respimg";

import Components from './components'
import './app.css'

Vue.config.productionTip = false

new Vue({
  el: '#app',
  delimiters: ['${', '}'],
  data() {
    return {
      activeNav: false
    }
  }
})
